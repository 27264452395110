
import Badge1Icon from "../assets/badges/badge_1.png";
import Badge2Icon from "../assets/badges/badge_2.png";
import Badge3Icon from "../assets/badges/badge_3.png";
import Badge4Icon from "../assets/badges/badge_4.png";
import Badge5Icon from "../assets/badges/badge_5.png";
import Badge6Icon from "../assets/badges/badge_6.png";
import Badge7Icon from "../assets/badges/badge_7.png";

export const getWDInfo = (wdItemKey) =>{
  let rules;
  let title;
  let task;
  let task_title;
  switch(wdItemKey){
    case 'deposit_and_trade':
      title = 'Newcomer First Trade / First Deposit';
      task_title = `Make a trade or deposit`;
      task = `Make a trade or a deposit of any amount on BingX for the first time.`;
      rules =[
        'You must register with BingX via our specified link.',
        `The eligible period begins from the registeration date of WinX's newest version.`,
        'The rewards are one-time only and cannot be claimed repeatedly.',
      ];
      break;
    case 'spot_trade_300':
      title = 'Spot Trade Volume Reaches 300 USDT';
      task_title = `Trade spot for 300 USDT`;
      task = `Trade spot on BingX and reach a cumulative volume of 300 USDT.`;
      rules =[
        'You must register with BingX via our specified link.',
        `The eligible period begins from the registeration date of WinX's newest version.`,
        'The rewards are one-time only and cannot be claimed repeatedly.',
      ];
      break;
    case 'spot_trade_1000':
      title = 'Spot Trade Volume Reaches 1,000 USDT';
      task_title = `Trade spot for 1,000 USDT`;
      task = `Trade spot on BingX and reach a cumulative volume of 1,000 USDT.`;
      rules =[
        'You must register with BingX via our specified link.',
        `The eligible period begins from the registeration date of WinX's newest version.`,
        'The rewards are one-time only and cannot be claimed repeatedly.',
      ];
      break;
    case 'spot_trade_3000':
      title = 'Spot Trade Volume Reaches 3,000 USDT';
      task_title = `Trade spot for 3K USDT`;
      task = `Trade spot on BingX and reach a cumulative volume of 3,000 USDT.`;
      rules =[
        'You must register with BingX via our specified link.',
        `The eligible period begins from the registeration date of WinX's newest version.`,
        'The rewards are one-time only and cannot be claimed repeatedly.',
      ];
      break;
    case 'contract_trade_1000':
      title = 'Futures Trade Volume Reaches 1,000 USDT';
      task_title = `Trade futures for 1,000 USDT`;
      task = `Trade futures on BingX and reach a cumulative volume of 1,000 USDT.`;
      rules =[
        'You must register with BingX via our specified link.',
        `The eligible period begins from the registeration date of WinX's newest version.`,
        'The rewards are one-time only and cannot be claimed repeatedly.',
      ];
      break;
    case 'contract_trade_5000':
      title = 'Futures Trade Volume Reaches 5,000 USDT';
      task_title = `Trade futures for 5,000 USDT`;
      task = `Trade futures on BingX and reach a cumulative volume of 5,000 USDT.`;
      rules =[
        'You must register with BingX via our specified link.',
        `The eligible period begins from the registeration date of WinX's newest version.`,
        'The rewards are one-time only and cannot be claimed repeatedly.',
      ];
      break;
    case 'contract_trade_10000':
      title = 'Futures Trade Volume Reaches 10K USDT';
      task_title = `Trade futures for 10K USDT`;
      task = `Trade futures on BingX and reach a cumulative volume of 10K USDT.`;
      rules =[
        'You must register with BingX via our specified link.',
        `The eligible period begins from the registeration date of WinX's newest version.`,
        'The rewards are one-time only and cannot be claimed repeatedly.',
      ];
      break;
    case 'contract_trade_100000':
      title = 'Futures Trade Volume Reaches 100K USDT';
      task_title = `Trade futures for 100K USDT`;
      task = `Trade futures on BingX and reach a cumulative volume of 100K USDT.`;
      rules =[
        'You must register with BingX via our specified link.',
        `The eligible period begins from the registeration date of WinX's newest version.`,
        'The rewards are one-time only and cannot be claimed repeatedly.',
      ];
      break;
    case 'contract_trade_200000':
      title = 'Futures Trade Volume Reaches 200K USDT';
      task_title = `Trade futures for 200K USDT`;
      task = `Trade futures on BingX and reach a cumulative volume of 200K USDT.`;
      rules =[
        'You must register with BingX via our specified link.',
        `The eligible period begins from the registeration date of WinX's newest version.`,
        'The rewards are one-time only and cannot be claimed repeatedly.',
      ];
      break;
    default:
      title = 'XXXXXXXXXXXX';
      task_title = `XXXXXXXXXXXX`;
      task = 'XXXXXXXXXXXX';
      rules =[
        'xxxxxxxxxxxxxxxxx',
        'xxxxxxxxxxxxxxxxx',
        'xxxxxxxxxxxxxxxxx',
        'xxxxxxxxxxxxxxxxx',
        'xxxxxxxxxxxxxxxxx',
        'xxxxxxxxxxxxxxxxx',
      ];
      break;
  }
  return {rules, title, task_title, task};
}

export const getBadgeInfo = (badgeKey) => {
  let BadgeIcon;
  let BadgeName;
  switch (badgeKey) {
    case 'newcommer':
      BadgeIcon = Badge1Icon;
      BadgeName = 'Newcomer';
      break;
    case 'amateur':
      BadgeIcon = Badge2Icon;
      BadgeName = 'Amateur';
      break;
    case 'intermediate':
      BadgeIcon = Badge3Icon;
      BadgeName = 'Intermediate';
      break;
    case 'specialist':
      BadgeIcon = Badge4Icon;
      BadgeName = 'Specialist';
      break;
    case 'elite':
      BadgeIcon = Badge5Icon;
      BadgeName = 'Elite';
      break;
    case 'expert':
      BadgeIcon = Badge6Icon;
      BadgeName = 'Expert';
      break;
    case 'superstar':
      BadgeIcon = Badge7Icon;
      BadgeName = 'Superstar';
      break;
    default:
      BadgeIcon = Badge1Icon;
      BadgeName = 'Newcomer';
  }
  return {BadgeIcon, BadgeName}
}
