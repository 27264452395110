import './App.css';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import LoginPage from './pages/login/LoginPage';
import WdCenterPage from './pages/withdraw/WdCenterPage';
import {AuthProvider} from './context/AuthContext';
import AppleAuthPage from './pages/login/AppleAuthPage';
import GoogleAuthPage from './pages/login/GoogleAuthPage';
import AppAuthPage from './pages/login/AppAuthPage';
import axios from "axios";


const App = () =>{
  axios.defaults.headers.common["device-type"] = "web";
  return (
    <>
      <BrowserRouter>
      <AuthProvider>
        {/* <NavBar /> */}
        <Routes>
          <Route exact path="/login" element={<LoginPage/>}/>
          <Route exact path="/" element={<WdCenterPage/>} />
          <Route exact path="/apple-auth" element={<AppleAuthPage/>} />
          <Route exact path="/google-auth" element={<GoogleAuthPage/>} />
          <Route exact path="/app_call_back" element={<AppAuthPage/>} />
        </Routes>
        </AuthProvider>
      </BrowserRouter>
    </>
  )
}
export default App;