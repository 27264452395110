import axios from "axios";

const HOST_LOC = 'http://13.215.99.71:3003';
const HOST_DEV = 'https://sanbox-api.winx.live';
const HOST_PRD = 'https://api.winx.live';
const HOST = HOST_PRD;

export const SendCode = async (email) => {
  try {
    const response = await axios.post(`${HOST}/winx/v1/user/email-code`,{
      data:{ email:email}
    });
    const { code } = response.data;
    return {status:200, code:code};
  } catch (error) {
    if(axios.isAxiosError(error)){
      if(!error.response) return {status:502, code:502};
      return {status:error.response.status, code:error.response.status }
    }
    console.log(error);
  }
}


export const LoginWithEmail = async(email, passCode) =>{
  try{
    const response = await axios.post(`${HOST}/winx/v1/user/login`,{
      data:{
        email:email,
        pass_code: passCode,
        account_source: 0,
        keep_login:1,
        register_require:1,
      }
    });
    const { code, data } = response.data;
    return { status:200, code:code, data};
  }catch(error){
    if(axios.isAxiosError(error)){
      if(!error.response) return {status:502, code:502};
      return {status:error.response.status, code:error.response.status}
    }
    console.log(error);
  }
}

export const LoginWithGoogle = async(token) => {
  try{
    const response = await axios.post(`${HOST}/winx/v1/user/login`,{
      data:{
        token:token,
        account_source: 1,
        keep_login:1,
        register_require:1,
      }
    });
    const { code, data } = response.data;
    return { status:200, code:code, data};
  }catch(error){
    if(axios.isAxiosError(error)){
      if(!error.response) return {status:502, code:502};
      return {status:error.response.status, code:error.response.status}
    }
    console.log(error);
  }
}

export const LoginWithApple = async(token) => {
  try{
    const response = await axios.post(`${HOST}/winx/v1/user/login`,{
      data:{
        token:token,
        account_source: 2,
        keep_login:1,
        register_require:1,
      }
    });
    const { code, data } = response.data;
    return { status:200, code:code, data};
  }catch(error){
    if(axios.isAxiosError(error)){
      if(!error.response) return {status:502, code:502};
      return {status:error.response.status, code:error.response.status}
    }
    console.log(error);
  }
}


export const UserProfile = async(authToken) => {
  try{
    const response = await axios.get(`${HOST}/winx/v1/user/profile`,{
      headers:{
        'Authorization':'Bearer ' + String(authToken)
      }
    });
    const { code, data } = response.data;
    return { status:200, code:code, data};
  }catch(error){
    if(axios.isAxiosError(error)){
      if(!error.response) return {status:502, code:502};
      return {status:error.response.status, code:error.response.status}
    }
    console.log(error);
  }
}

export const WithDrawDetail = async(authToken) =>{
  try{
    const response = await axios.get(`${HOST}/winx/v1/withdraw/tasks`,{
      headers:{
        'Authorization':'Bearer ' + String(authToken)
      }
    });
    const { code, data, err_msg } = response.data;
    return { status:200, code:code, data, err_msg:err_msg?err_msg:''};
  }catch(error){
    if(axios.isAxiosError(error)){
      if(!error.response) return {status:502, code:502};
      return {status:error.response.status, code:error.response.status, err_msg:'can not access'}
    }
    console.log(error);
  }
}

export const WithDraw = async(authToken, itemKey) => {
  try{
    const payload = {
      data:{ item_key: itemKey}
    }
    const response = await axios.post(`${HOST}/winx/v1/withdraw`,payload,{
      headers:{'Authorization':'Bearer ' + String(authToken)}
    });
    const { code, data, err_msg } = response.data;
    return { status:200, code:code, data, err_msg:err_msg?err_msg:''};
  }catch(error){
    if(axios.isAxiosError(error)){
      if(!error.response) return {status:502, code:502};
      return {status:error.response.status, code:error.response.status, err_msg:'can not access'}
    }
    console.log(error);
  }
}

export const BindBingx = async(authToken, bingxUID) => {
  try{
    const payload = {
      data:{ bingx_uid: bingxUID}
    }
    const response = await axios.post(`${HOST}/winx/v1/airdrop/bingx-bind`,payload,{
      headers:{'Authorization':'Bearer ' + String(authToken)}
    });
    const { code, data, err_msg } = response.data;
    return { status:200, code:code, data, err_msg:err_msg?err_msg:''};
  }catch(error){
    if(axios.isAxiosError(error)){
      if(!error.response) return {status:502, code:502};
      return {status:error.response.status, code:error.response.status, err_msg:'can not access'}
    }
    console.log(error);
  }
}