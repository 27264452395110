import AuthContext from "../../context/AuthContext";
import React, { useEffect, useState, useCallback,useContext } from 'react';
import { useNavigate } from "react-router-dom";


const AppAuthPage = () => {
  const searchParams = new URLSearchParams(window.location.search);
  const { setToken } = useContext(AuthContext);
  const navigate = useNavigate();
  useEffect(() => {
    const accessToken = searchParams.get('access_token');
    setToken(accessToken);
    navigate('/', {replace:true});
  },[]);
  return (
    <></>
  );
}

export default AppAuthPage;

