import "./WdCenterPage.css";
import React, { useEffect, useState, useContext, CSSProperties } from 'react';
import { useNavigate } from "react-router-dom";
import BingXLogo from "../../assets/withdraw_bingx.png";
import GoldIcon from "../../assets/withdraw_gold.png";
import USDTIcon from "../../assets/withdraw_usdt.png";
import AirDropIcon from "../../assets/withdraw_airdrop.png";
import CompletedIcon from "../../assets/withdraw_completed.png";
import ModalWarningIcon from "../../assets/modal_warning.png";
import ModalCopiedIcon from "../../assets/modal_copied.png";
import TaskFailedIcon from "../../assets/withdraw_task_failed.png";
import TaskSuccessIcon from "../../assets/withdraw_task_success.png";
import WinxLogoIcon from "../../assets/winx_logo.png"
import AuthContext from '../../context/AuthContext';
import Modal from '../../components/Modal';
import {getWDInfo, getBadgeInfo} from '../../common/withdraw-lib';
import { WithDrawDetail, WithDraw, BindBingx } from '../../api/winx-api';
import {LoaderPage} from '../../components/Loading';


const WDListHeader = () =>{
  const { authToken, setToken } = useContext(AuthContext);
  const navigate = useNavigate();
  const handleLogout = () =>{
    setToken(null);
    navigate('/login',{replace:true});
  }
  return (
    <>
      <div className='wd-list-header'>
        <img src={WinxLogoIcon}></img>
        {/* <button className='wd-list-header-button' onClick={handleLogout}>Sign Out</button> */}
      </div>
    </>
  );
}

const WDListWallet = (props) => {
  const { balance, balanceUSDT } = props;
  const formatMoney = (value) => {
    if(!value) return '0.00'; 
    const formatter = new Intl.NumberFormat('en-US', {
      currency: 'USD',
    });
    return formatter.format(value);
  };
  return (
    <div className='wd-list-wallet'>
      <div className='wd-list-wallet-base'>
        <h1>Your WinX GOLDs</h1>
        <p>
          <img className="wd-list-wallet-base-gold-icon" src={GoldIcon} />
          <span className='wd-list-wallet-base-gold'>{formatMoney(balance)}</span>
        </p>
      </div>
      <div className='wd-list-wallet-floating'>
        <h1 className="wd-list-wallet-floating-title">Estimated Value</h1>
        <p>≈ <span className="wd-list-wallet-floating-usdt">{balanceUSDT}</span> USDT</p>
      </div>
    </div>
  )
}

const WDListItem = (props) => {
  const { wdItemKey, usdt, points, badgeKey, processStatus } = props;
  const { onWdItemChange } = props;
  const {title} = getWDInfo(wdItemKey)
  const {BadgeIcon} = getBadgeInfo(badgeKey);

  const handleWdItemClick = () =>{
    onWdItemChange(wdItemKey);
  }
  const formatMoney = (value) => {
    if(!value) return '0'; 
    const formatter = new Intl.NumberFormat('en-US', {
      currency: 'USD',
    });
    return formatter.format(value);
  };

  return (
    <div className='ws-list-box-container'>
      <div className='wd-list-box-shadow'></div>
      <div className='wd-list-box' onClick={handleWdItemClick}>
        <div className="wd-list-item">
          <img className='wd-list-item-logo' src={BingXLogo} />
          <h1 className='wd-list-item-title'>{title}</h1>
          <div className='wd-list-item-rewards'>
            <div className='wd-list-item-reward usdt'><img src={USDTIcon} alt=""/><span>{usdt} USDT</span></div>
            <div className='wd-list-item-reward airdrop'><img src={AirDropIcon} alt=""/><span>{formatMoney(points)} Points</span></div>
            <img src={BadgeIcon} className='wd-list-item-reward-badge'/>
          </div>
        </div>
        {processStatus===2?(<div className='wd-list-item-completed'><img src={CompletedIcon} /><h3>Completed</h3></div>):(<></>)}
      </div>
    </div>
  )
}

const WDList = (props) => {
  const { wdItems, wallet, onWdItemChange } = props;
  return (
    <>
      <WDListHeader />
      <div className='wd-list-page'>
        <WDListWallet balance={wallet.balance} balanceUSDT={wallet.balance_usdt} />
        <div className='wd-list-items'>
          <h1 className='wd-list-items-title'>Withdrawal Events</h1>
          {wdItems.map((item, i) => {
            return (<WDListItem key={i} wdItemKey={item.item_key} usdt={item.usdt} points={item.score} badgeKey={item.medal_key} processStatus={item.process_status} onWdItemChange={onWdItemChange} />)
          })}
        </div>
      </div>
    </>
  )
}

const WDDetailHeader = (props) =>{
  const {onGoBackClick} = props
  return (
    <div className='wd-detail-header'>
    <div className='wd-detail-header-goback' onClick={onGoBackClick}>
    <svg xmlns="http://www.w3.org/2000/svg" width="11" height="13" viewBox="0 0 11 13" fill="none">
    <g clipPath="url(#clip0_223_2350)">
    <path d="M6.29346 11.5867C4.80767 9.60562 3.04785 7.84579 1.06679 6.36C3.04785 4.87421 4.80767 3.11439 6.29346 1.13334" stroke="black" strokeWidth="1.49333" strokeLinejoin="round"/>
    <path d="M1.06681 5.61328H10.0268C10.233 5.61328 10.4001 5.78043 10.4001 5.98661V6.73328C10.4001 6.93947 10.233 7.10661 10.0268 7.10661H1.06681C0.860627 7.10661 0.69348 6.93947 0.69348 6.73328V5.98661C0.69348 5.78043 0.860627 5.61328 1.06681 5.61328Z" fill="black"/>
    </g>
    <defs>
    <clipPath id="clip0_223_2350">
    <rect width="10.08" height="11.9467" fill="white" transform="translate(0.320068 0.386719)"/>
    </clipPath>
    </defs>
    </svg>
    </div>
    <h1>Event Details</h1>
  </div>
  )
}

const WDDetailReward = (props) =>{
  const {usdt, points, badgeKey, claimReward, balanceUSDT, processStatus, percent} = props;
  const {BadgeIcon, BadgeName} = getBadgeInfo(badgeKey);
  const formatMoney = (value) => {
    if(!value) return '0'; 
    const formatter = new Intl.NumberFormat('en-US', {
      currency: 'USD',
    });
    return formatter.format(value);
  };

  return (<>
    <div className='wd-detail-reward'>
      <h1 className='wd-detail-reward-title'>Rewards</h1>
      <div className='wd-detail-box-container'>
      <div className='wd-detail-box-shadow'></div>
        <div className='wd-detail-box'>
          <div className='wd-detail-reward-list'>
            <div className='wd-detail-reward-item'>
              <div className='wd-detail-reward-item-img'>
                <img src={USDTIcon}/>
              </div>
              <div className='wd-detail-reward-item-text'>
                <h3>{usdt}</h3>
                <p>USDT</p>
              </div>
            </div>

            <div className='wd-detail-reward-item'>
              <div className='wd-detail-reward-item-img'>
                <img src={AirDropIcon}/>
              </div>
              <div className='wd-detail-reward-item-text'>
                <h3>{formatMoney(points)}</h3>
                <p>Points</p>
              </div>
            </div>

            <div className='wd-detail-reward-item'>
              <div className='wd-detail-reward-item-img'>
                <img src={BadgeIcon}/>
              </div>
              <div className='wd-detail-reward-item-text'>
                <h3>{BadgeName}</h3>
                <p>Badge</p>
              </div>
            </div>
          </div>
        </div>

      </div>
      {balanceUSDT < usdt?(<h3 className='wd-detail-reward-warning'>Insufficient balance available for withdrawal.</h3>):(<></>)}
      <div className="wd-detail-reward-button-group">
      {processStatus === 0
      ?(<div className={balanceUSDT < usdt || percent !== 100 ?'wd-detail-reward-button grey':'wd-detail-reward-button'} onClick={claimReward}>Claim Rewards</div>)
      :(<div className='wd-detail-reward-button grey' onClick={claimReward}>Pending.....</div>)
      }
    </div>
    </div>
  </>)
}

const WDDetailRule = (props) =>{
  const {wdItemKey} = props;
  const [withdrawRules, setWithdrawRules] = useState([]);
  useEffect(() => {
    const {rules} = getWDInfo(wdItemKey);
    setWithdrawRules(rules);
  },[]);
  return (
    <div className='wd-detail-rule'>
      <h1>Notices:</h1>
      <ol>
        {withdrawRules.map((rule,i)=>(<li key={i}>{rule}</li>))}
      </ol>
    </div>
  )
}

const TaskCircle = (props) =>{
  const {percent, number} = props;
  const formatMoney = (value) =>{
    const number = Number(value);
    const units = ["K", "M", "B", "T"];
    let unitIndex = 0;
    let formattedNumber = number.toString();
    while (formattedNumber.length >= 5) {
      formattedNumber = (parseFloat(formattedNumber) / 1000).toFixed(0).toString();
      unitIndex++;
    }
    if (unitIndex > 0) {
      formattedNumber += units[unitIndex - 1];
    }
    return formattedNumber.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  return (
    <>
      {number !== 'N/A' ?
          <div className='withdraw-page-task-graph'>
          <svg>
          <circle cx="28" cy="28" r="23"></circle>
          <circle cx="28" cy="28" r="23" style={{'--percent': percent}}></circle>
          </svg>
          <div className="withdraw-page-task-graph-number">
              <span>{formatMoney(number)}</span>
          </div>
        </div>
      :<></>}
    </>
  )
}

const WDDetailTask = (props) =>{
  const {wdItemKey, bindBingx, bingxState, bingxLink, percent, volume} = props;
  const [newBingxUID, setNewBingxUID] = useState('');
  const [warnModalOpen, setWarnModalOpen] = useState(false);
  const [bindModelOpen, setBindModelOpen] = useState(false);
  const [copiedModelOpen, setCopiedModelOpen] = useState(false);
  const [copiedCounDown, setCopiedCounDown] = useState(0);
  // const navigate = useNavigate();

  const { task_title, task } = getWDInfo(wdItemKey)

  const openWarnModal = () => {
    setWarnModalOpen(true);
  };
  const closeWarnModal = () => {
    setWarnModalOpen(false);
  };
  const openBindModal = () => {
    setBindModelOpen(true);
  };
  const closeBindModal = () => {
    setBindModelOpen(false);
  };
  const handleVerifyClick = () =>{
    openWarnModal()
  }
  const handleLinkNowClick = () =>{
    openBindModal()
  }
  const handleNewBingXInput = (event) => {
    setNewBingxUID(event.target.value);
  }
  const confirmLinkNow = async () =>{
    const success = await bindBingx(newBingxUID);
    if(!success) return;
    setBindModelOpen(false);
  }

  useEffect(() => {
    let interval;
    if(copiedModelOpen){
      interval = setInterval(() => {
        setCopiedCounDown((copiedCounDown) => {
          if (copiedCounDown === 0) {
            setCopiedModelOpen(false);
            clearInterval(interval);
            return 0;
          }
          return copiedCounDown - 1;
        });
      }, 1000);
    }
    return () => clearInterval(interval);
  }, [copiedModelOpen]);

  const copyBingXRegister = async () =>{
    await navigator.clipboard.writeText(bingxLink);
    setCopiedCounDown(3);
    setCopiedModelOpen(true);
  }

  const closeCopiedModal = () =>{
    setCopiedModelOpen(false);
  }

  const jumpToBingXRegister = () =>{
    window.open(bingxLink, '_blank');
  }

  const jumpToBingxHelp = () =>{
    window.open('https://www.winx.live/bingx_uid', '_blank');
  }



  return (<>
  <div className='wd-detail-task'>
    <div className='wd-detail-box-container'>
      <div className='wd-detail-box-shadow'></div>
      <div className='wd-detail-box'>
        <div className='wd-detail-timeline'>
        <div className='wd-detail-timeline-item'>
          <div className={bingxState===2?"wd-detail-timeline-item-node-completed":"wd-detail-timeline-item-node"}></div>
          <div className="wd-detail-timeline-item-line"></div>
          <div className='wd-detail-timeline-item-title'>
            <span>Step 1: Link your BingX account</span>
          </div>
          <div className='wd-detail-timeline-item-content'>
            <div>
            <div className='wd-detail-timeline-item-content-row'>
              <p>Register for a BingX account via our specified link, then link your BingX account before cashing out your rewards.</p>
            </div>
            <div className='wd-detail-timeline-item-content-row'>
              <div className='wd-detail-timeline-item-content-link'>
                <span className="wd-detail-timeline-item-content-text" onClick={copyBingXRegister}>Copy Link</span>
              </div>
              <div className='wd-detail-timeline-item-content-link'>
                <span className="wd-detail-timeline-item-content-text" onClick={jumpToBingXRegister}>Register</span>
              </div>
            </div>
            
            {bingxState === 0
            ?(
              <div className='wd-detail-timeline-item-content-row'>
                <div className='wd-detail-timeline-item-content-link black-link' onClick={handleLinkNowClick}>
                  <span className="wd-detail-timeline-item-content-text black-link">Link Now</span>
                </div>
              </div>
            ):(
              <></>
            )}
            {bingxState === 1
            ?(
              <div className='wd-detail-timeline-item-content-row'>
                <div className='wd-detail-timeline-item-content-link grey-link'>
                  <span className="wd-detail-timeline-item-content-text grey-link">Verifying...</span>
                </div>
              </div>
            ):(
              <></>
            )}
            {bingxState === 2
            ?(
              <div className='wd-detail-timeline-item-content-row'>
                <div className='wd-detail-timeline-item-content-notice'>
                  <img src={TaskSuccessIcon}/>
                  <span className='wd-detail-timeline-item-content-text success'>Successfully linked!</span>
                </div>
              </div>
            ):(
              <></>
            )}
            {bingxState === 3
            ?(
              <div className='wd-detail-timeline-item-content-row'>
                <div className='wd-detail-timeline-item-content-link black-link' onClick={handleLinkNowClick}>
                  <span className="wd-detail-timeline-item-content-text black-link">Link Now</span>
                </div>
                <div className='wd-detail-timeline-item-content-notice'>
                  <img src={TaskFailedIcon}/>
                  <span className='wd-detail-timeline-item-content-text failed'>Failed. Please retry.</span>
                </div>
              </div>
            ):(
              <></>
            )}
            <div className="wd-detail-timeline-item-gap"></div>
            </div>
          </div>
        </div>
        <div className='wd-detail-timeline-item'>
          <div className={percent===100?"wd-detail-timeline-item-node-completed":"wd-detail-timeline-item-node"}></div>
          <div className='wd-detail-timeline-item-title'>
            Step 2: {task_title}
          </div>
          <div className='wd-detail-timeline-item-content'>
            <div className='wd-detail-timeline-item-content-row'>
              <p>{task}</p>
              <TaskCircle percent={percent} number={!volume?'N/A':volume}/>
            </div>
            <div className='wd-detail-timeline-item-content-row'>
              <div className='wd-detail-timeline-item-content-link' onClick={jumpToBingXRegister}>
                <span className="wd-detail-timeline-item-content-text">Go Now</span>
              </div>
            </div>
            {percent===100
            ?(
              <div className='wd-detail-timeline-item-content-row'>
              <div className='wd-detail-timeline-item-content-notice'>
                <img src={TaskSuccessIcon}/>
                <span className='wd-detail-timeline-item-content-text success'>Completed!</span>
              </div>
              </div>
            ):(
            <></>
            )}
            {bingxState!==2
            ?(
              <div className='wd-detail-timeline-item-content-notice'>
                <p><span className='wd-detail-timeline-item-content-text failed'>You must first link your BingX account.</span></p>
              </div>
            ):(
            <></>
            )}
            {/* <TaskCircle percent={percent} number={!volume?'N/A':volume}/> */}
          </div>
        </div>
        </div>
      </div>
    </div>
  </div>
  <Modal isOpen={warnModalOpen} onClose={closeWarnModal}>
    <div className='withdraw-page-warning-modal'>
      <img src={ModalWarningIcon} />
      <p>You must verify your BingX account's UID before proceeding.</p>
      <button onClick={closeWarnModal}>OK</button>
    </div>
  </Modal>
  <Modal isOpen={bindModelOpen} onClose={closeBindModal}>
    <div className='withdraw-page-bind-modal'>
      <h1>Link My BingX Account</h1>
      <h3><span>BingX Account's UID</span><svg onClick={jumpToBingxHelp} xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
        <g clipPath="url(#clip0_223_2153)">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M6.01206 8.1333C6.01206 8.1333 6.01206 8.31297 6.2078 8.31297H7.40813C7.40813 8.31297 7.60388 8.31297 7.60388 8.13676V7.95017C7.60388 7.95017 7.60388 7.68067 7.79593 7.49409C7.79593 7.49409 7.98798 7.30405 8.39794 7.04491C8.39794 7.04491 8.78204 6.79613 9.0295 6.58882C9.0295 6.58882 9.28064 6.37805 9.45423 6.05326C9.45423 6.05326 9.63151 5.72502 9.63151 5.26893C9.63151 5.26893 9.63151 4.66427 9.31388 4.20818C9.31388 4.20818 8.99626 3.74864 8.41271 3.49641C8.41271 3.49641 7.82917 3.24072 7.05727 3.24072C7.05727 3.24072 6.2632 3.24072 5.6538 3.50332C5.6538 3.50332 5.0481 3.76591 4.70832 4.24619C4.70832 4.24619 4.36853 4.72301 4.36853 5.34149V5.45551C4.36853 5.45551 4.36853 5.50734 4.42393 5.54535C4.42393 5.54535 4.48302 5.5799 4.56058 5.5799H5.77568C5.77568 5.5799 5.96774 5.5799 5.96774 5.41405V5.30003C5.96774 5.30003 5.96774 4.96142 6.25582 4.74374C6.25582 4.74374 6.54759 4.52606 7.02403 4.52606C7.02403 4.52606 7.46722 4.52606 7.73684 4.73337C7.73684 4.73337 8.01014 4.93723 8.01014 5.27584C8.01014 5.27584 8.01014 5.59027 7.79224 5.81485C7.79224 5.81485 7.57802 6.03599 7.13852 6.31932C7.13852 6.31932 6.59191 6.67866 6.30014 6.99999C6.30014 6.99999 6.01206 7.32133 6.01206 7.84997V8.1333ZM6.19303 10.5001C6.19303 10.5001 6.47741 10.7593 6.87629 10.7593C6.87629 10.7593 7.27148 10.7593 7.55956 10.5001C7.55956 10.5001 7.85133 10.241 7.85133 9.87473C7.85133 9.87473 7.85133 9.50848 7.55956 9.24933C7.55956 9.24933 7.27148 8.99019 6.88737 8.99019C6.88737 8.99019 6.48849 8.99019 6.20042 9.24933C6.20042 9.24933 5.91234 9.50848 5.91234 9.87473C5.91234 9.87473 5.91234 10.241 6.19303 10.5001Z" fill="#323232"/>
        <circle cx="7" cy="7" r="6.34375" stroke="#323232" stroke-width="1.3125"/>
        </g>
        <defs>
        <clipPath id="clip0_223_2153">
        <rect width="14" height="14" fill="white"/>
        </clipPath>
        </defs>
        </svg>
      </h3>
      <input type="number" value={newBingxUID} onChange={handleNewBingXInput}/>
      <h5>You must register with BingX via our specified link and obtain your UID, or it may affect normal receipt of funds.</h5>
      <div className="withdraw-page-bind-modal-button" onClick={confirmLinkNow}><span>LinkNow</span></div>
    </div>
    <div className="withdraw-page-bind-modal-register">
      <p>Don't have a BingX Accout</p>
      <div className="withdraw-page-bind-modal-register-button" onClick={jumpToBingXRegister}>Register</div>
    </div>
  </Modal>
  <Modal isOpen={copiedModelOpen} onClose={closeCopiedModal}>
    <div className='withdraw-page-warning-modal'>
      <img src={ModalCopiedIcon} />
      <p>Copied!</p>
    </div>
  </Modal>
  </>)
}


const WDDetail = (props) =>{
  const { wdItem, wallet, onWithdrawClick, onWdItemChange, onBingxBindClick} = props;
  const handleGoBack = () =>{
    onWdItemChange(undefined);
  }
  const handleBingxBind = async (newBingxUID) =>{
    return onBingxBindClick(newBingxUID);
  }
  const handleClaimReward = async () =>{
    return onWithdrawClick(wdItem.item_key)
  }
  const {title} = getWDInfo(wdItem.item_key);
  return (
    <>
      <WDDetailHeader onGoBackClick={handleGoBack}/>
      <div className='wd-detail-page'>
        <img src={BingXLogo} alt="" className='wd-detail-logo'/>
        <h1 className='wd-detail-title'>{title}</h1>
        <WDDetailTask wdItemKey={wdItem.item_key} bindBingx={handleBingxBind} bingxState={wallet.bingx_state} bingxLink={wallet.bingx_link} percent={wdItem.percent} volume={wdItem.volume}/>
        <WDDetailRule wdItemKey={wdItem.item_key}/>
        <WDDetailReward wdItemKey={wdItem.item_key} usdt={wdItem.usdt} balanceUSDT={wallet.balance_usdt}  points={wdItem.score} badgeKey={wdItem.medal_key} processStatus={wdItem.process_status} percent={wdItem.percent} claimReward={handleClaimReward}/>
      </div>
    </>
  )
}

const WdCenterPage = () => {
  const [wdItems, setWdItems] = useState([]);
  const [wallet, setWallet] = useState({});
  const [wdItem, setWdItem] = useState(undefined);
  const [currentItemKey, setCurrentItemKey] = useState(undefined);
  const { authToken, setToken } = useContext(AuthContext);
  const [isPageLoading, setIsPageLoading] = useState(false);
  const navigate = useNavigate();

  const refreashWdItems = async () =>{
    if(!authToken) {
      navigate('/login',{replace:true});
      return;
    }
    setIsPageLoading(true);
    const response = await WithDrawDetail(authToken);
    setIsPageLoading(false);
    if(response.status === 401 || response.code === 401){
      setToken(null);
      navigate('/login',{replace:true});
      return;
    }
    if(response.code !== 200){
      console.log(response.code);
      return;
    }
    const {withdraw_items, balance, balance_usdt, bingx_state, bingx_link } = response.data;
    setWdItems(withdraw_items);
    const wallet = {balance, balance_usdt, bingx_state, bingx_link};
    setWallet({...wallet});
  }

  const handleWdItemChange = (itemKey) =>{
    console.log('handleWdItemChange', itemKey);
    const item = wdItems.find((i)=>i.item_key===itemKey);
    if(item && item.process_status === 2) return;
    setCurrentItemKey(itemKey);
    setWdItem(item);
  }

  const handleWithdraw = async (itemKey) => {
    console.log('handleWithdraw', itemKey);
    const item = wdItems.find((i)=>i.item_key===itemKey);
    if(item.process_status !== 0) return false;
    if(item.percent !== 100) return false;
    if(wallet.balance_usdt < wdItem.usdt) return false;
    setIsPageLoading(true);
    const response = await WithDraw(authToken, itemKey);
    setIsPageLoading(false);
    if(response.code !== 200) {
      alert('Request failed, please try again later');
      return false;
    }
    await refreashWdItems();
    return true;
  }

  const handleBingxBind = async (bingxUID) => {
    console.log('handleBingxBind', bingxUID);
    setIsPageLoading(true);
    const response = await BindBingx(authToken, bingxUID);
    setIsPageLoading(false);
    if(response.code !== 200){
      alert(response.err_msg);
      return false;
    }
    await refreashWdItems();
    return true;
  }

  useEffect(()=>{
    refreashWdItems();
  },[]);

  useEffect(()=>{
    const item = wdItems.find((i)=>i.item_key===currentItemKey);
    setWdItem(item);
  },[wdItems]);

  return (
    <>
      {isPageLoading?<LoaderPage />:(<></>)}
      {wdItem
      ?(<WDDetail wdItem={wdItem} wallet={wallet} onWdItemChange={handleWdItemChange} onWithdrawClick={handleWithdraw} onBingxBindClick={handleBingxBind}/>)
      :(<WDList wdItems={wdItems} wallet={wallet} onWdItemChange={handleWdItemChange}/>)}
    </>
  )
}
export default WdCenterPage;





