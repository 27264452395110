import './Modal.css'


const Model = (props) => {
  const {isOpen, onClose, children} = props;
  if (!isOpen) {
    return null;
  }
  return (
    <div className="modal-overlay">
      <div className="modal">
        <div className="modal-close" onClick={onClose}>
          <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
          <g clip-path="url(#clip0_223_1955)">
          <mask id="path-1-outside-1_223_1955" maskUnits="userSpaceOnUse" x="5.65314" y="5.65332" width="17" height="17" fill="black">
          <rect fill="white" x="5.65314" y="5.65332" width="17" height="17"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M12.8065 13.9999L6.65314 20.1533L7.84638 21.3466L13.9998 15.1932L20.1531 21.3466L21.3464 20.1533L15.193 13.9999L21.3464 7.84654L20.1531 6.65332L13.9998 12.8067L7.84639 6.65332L6.65314 7.84654L12.8065 13.9999Z"/>
          </mask>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M12.8065 13.9999L6.65314 20.1533L7.84638 21.3466L13.9998 15.1932L20.1531 21.3466L21.3464 20.1533L15.193 13.9999L21.3464 7.84654L20.1531 6.65332L13.9998 12.8067L7.84639 6.65332L6.65314 7.84654L12.8065 13.9999Z" fill="black"/>
          <path d="M6.65314 20.1533L6.35483 19.855L6.05652 20.1533L6.35482 20.4516L6.65314 20.1533ZM12.8065 13.9999L13.1048 14.2982L13.4031 13.9999L13.1048 13.7016L12.8065 13.9999ZM7.84638 21.3466L7.54807 21.6449L7.84638 21.9432L8.14469 21.6449L7.84638 21.3466ZM13.9998 15.1932L14.2981 14.8949L13.9998 14.5966L13.7015 14.8949L13.9998 15.1932ZM20.1531 21.3466L19.8548 21.6449L20.1531 21.9432L20.4515 21.6449L20.1531 21.3466ZM21.3464 20.1533L21.6447 20.4516L21.943 20.1533L21.6447 19.855L21.3464 20.1533ZM15.193 13.9999L14.8947 13.7016L14.5964 13.9999L14.8947 14.2982L15.193 13.9999ZM21.3464 7.84654L21.6447 8.14485L21.943 7.84653L21.6447 7.54822L21.3464 7.84654ZM20.1531 6.65332L20.4514 6.35501L20.1531 6.0567L19.8548 6.35501L20.1531 6.65332ZM13.9998 12.8067L13.7015 13.105L13.9998 13.4033L14.2981 13.105L13.9998 12.8067ZM7.84639 6.65332L8.1447 6.35501L7.84639 6.0567L7.54808 6.35501L7.84639 6.65332ZM6.65314 7.84654L6.35484 7.54822L6.05652 7.84653L6.35483 8.14485L6.65314 7.84654ZM6.95145 20.4516L13.1048 14.2982L12.5082 13.7016L6.35483 19.855L6.95145 20.4516ZM8.1447 21.0483L6.95145 19.855L6.35482 20.4516L7.54807 21.6449L8.1447 21.0483ZM13.7015 14.8949L7.54807 21.0483L8.14469 21.6449L14.2981 15.4915L13.7015 14.8949ZM20.4515 21.0483L14.2981 14.8949L13.7015 15.4915L19.8548 21.6449L20.4515 21.0483ZM21.0481 19.855L19.8548 21.0483L20.4515 21.6449L21.6447 20.4516L21.0481 19.855ZM14.8947 14.2982L21.0481 20.4516L21.6447 19.855L15.4913 13.7016L14.8947 14.2982ZM21.0481 7.54823L14.8947 13.7016L15.4913 14.2982L21.6447 8.14485L21.0481 7.54823ZM19.8548 6.95163L21.0481 8.14485L21.6447 7.54822L20.4514 6.35501L19.8548 6.95163ZM14.2981 13.105L20.4514 6.95163L19.8548 6.35501L13.7015 12.5084L14.2981 13.105ZM7.54808 6.95163L13.7015 13.105L14.2981 12.5084L8.1447 6.35501L7.54808 6.95163ZM6.95145 8.14485L8.1447 6.95163L7.54808 6.35501L6.35484 7.54822L6.95145 8.14485ZM13.1048 13.7016L6.95146 7.54823L6.35483 8.14485L12.5082 14.2982L13.1048 13.7016Z" fill="black" mask="url(#path-1-outside-1_223_1955)"/>
          </g>
          <defs>
          <clipPath id="clip0_223_1955">
          <rect width="27" height="27" fill="white" transform="translate(0.5 0.5)"/>
          </clipPath>
          </defs>
          </svg>
        </div>
        <div className="modal-content">{children}</div>
      </div>
    </div>
  );
}


export default Model;