import './LoginPage.css';
import React, { useEffect, useState, useCallback, useContext } from 'react';
import WinxIcon from '../../assets/winx_icon.png';
import WinxLogo from '../../assets/winx_logo.png';
import LoginIcon from '../../assets/login_email.png';
import GoogleIcon from '../../assets/login_google2.png';
import AppleIcon from '../../assets/login_apple.png';
import AuthContext from "../../context/AuthContext";
import { SendCode, LoginWithEmail, LoginWithGoogle } from '../../api/winx-api';
import { useNavigate } from "react-router-dom";
import { Loader } from "../../components/Loading";

const LoginEmail = (props) =>{
  const {setPageState} = props;
  const [email, setEmail] = useState('');
  const [loginCode, setLoginCode] = useState('');
  const [canNext, setCanNext] = useState(false);
  const [errMessage, setErrMessage] = useState('');
  const [reSendTime, setReSendTime] = useState(0);
  const [isSending, setIsSending] = useState(false);
  const [sended, setSended] = useState(false);
  const [isPageLoading, setIsPageLoading] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const { setToken } = useContext(AuthContext);
  const navigate = useNavigate();

  useEffect(() => {
    let interval;
    if(isSending){
      interval = setInterval(() => {
        setReSendTime((prevSeconds) => {
          if (prevSeconds === 0) {
            setIsSending(false);
            clearInterval(interval);
            return 0;
          }
          return prevSeconds - 1;
        });
      }, 1000);
    }
    return () => clearInterval(interval);
  }, [isSending]);

  const handleLogin = async () =>{
    if(!canNext) return;
    setIsPageLoading(true);
    const response = await LoginWithEmail(email, loginCode);
    setIsPageLoading(false);
    if(response.code === 400002){
      setErrMessage('You must first register an account in the WinX app.')
      return;
    }

    if(response.code !== 200){
      setErrMessage('Invaild code, Please try again')
      return;
    }
    const accessToken = response.data.access_token;
    setToken(accessToken);
    navigate('/', {replace:true});
  }

  const handleSendCode = async () =>{
    if(isSending) return;
    if(email === '') {
      setErrMessage('Please enter a valid email address');
      return;
    }
    if(!isValid){
      setErrMessage('Please enter a valid email address');
      return;
    }

    setReSendTime(60);
    setIsSending(true);
    setSended(true);

    const response = await SendCode(email);
    if(response.code !== 200){
      setErrMessage('Failed to send code. Please retry.');
      return;
    }
  }
  const handleCancel = () =>{
    setPageState(0);
  }
  const handleEmailInput = (event) => {
    setEmail(event.target.value);
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const valid = emailPattern.test(event.target.value)
    setIsValid(valid);
    if(!valid) setCanNext(false);
    if(valid && loginCode!=='') setCanNext(true);
  }
  const handleCodeInput = (event) => {
    setLoginCode(event.target.value)
    if(isValid && event.target.value!=='') setCanNext(true);
  }
  return (
    <>
    <div className="login-email">
      <img src={WinxLogo} className='login-email-logo'/>
      <div className='login-email-form'>
        <div className='login-email-input'>
          <input type="text" placeholder='Email Address' value={email} onChange={handleEmailInput} />
        </div>
        <div className='login-email-input'>
          <input type="number" placeholder='Verification code' value={loginCode} onChange={handleCodeInput}/>
          {sended
          ? <button onClick={handleSendCode} className={isSending?'login-email-input-btn grey':'login-email-input-btn'}>Resend{reSendTime>0?' '+reSendTime:''}</button>
          : <button onClick={handleSendCode} className='login-email-input-btn'>Get code</button>
          }
        </div>
        <h3 className='login-email-error'>{errMessage}</h3>
      </div>
      <div className="login-email-btn-group">
        <div className={ canNext ? 'login-email-btn' : 'login-email-btn grey' } onClick={handleLogin}>
          {isPageLoading?<Loader />:'Next'}
        </div>
        <div className='login-email-btn-cancel' onClick={handleCancel}>Cancel</div>
      </div>
    </div>
    </>
  )
};

const GoogleLogin = (props) =>{
  useEffect(() => {
    if(!window || !window.google) return;
    window.google.accounts.id.initialize({
      client_id: '232649375954-m4h4n89un84r7ekbct101u7q8o0er72a.apps.googleusercontent.com',
      // login_uri: 'https://sanbox-api.winx.live/winx/v1/oauth2/google',
      login_uri: 'https://api.winx.live/winx/v1/oauth2/google',
      ux_mode:'redirect'
    });

    window.google.accounts.id.renderButton(document.getElementById('google-one-tap'), {
      theme: 'filled_blue',
      size: 'large',
    });
  }, [window.google]);
  const googleLogin = () =>{
    const googleLoginWrapperButton = document.getElementById('google-one-tap').querySelector("div[role=button]");
    googleLoginWrapperButton.click();
  }
  return (
    <>
    <div id="google-one-tap" hidden="1"></div>
    <div className='login-main-btn' onClick={googleLogin}>
      <img src={GoogleIcon} alt="" className="login-main-btn-icon"/>
      Continue With Google
    </div>
    </>
  );
}

const AppleLogin = () => {
  useEffect(() => {
    window.AppleID.auth.init({
      // clientId: 'com.winx.winxDev.sid',
      clientId: 'xyz.lenderboostn.winx。',
      scope: 'email name',
      // redirectURI: 'https://sanbox-api.winx.live/winx/v1/oauth2/apple',
      redirectURI: 'https://api.winx.live/winx/v1/oauth2/apple',
    });
  },[]);
  const handleSignIn = async () => {
    try {
      await window.AppleID.auth.signIn();
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <div className='login-main-btn' onClick={handleSignIn}>
      <img src={AppleIcon} alt="" className="login-main-btn-icon"/>
      Continue With Apple
    </div>
  );
}



const LoginMain = (props) => {
  const {setPageState} = props;
  const handleEmailLogin = () =>{
    setPageState(1)
  }
  return (
    <div className='login-main'>
    <img src={WinxIcon} className='login-main-winx-icon'/>
    <div className='login-main-btn' onClick={handleEmailLogin}>
      <img src={LoginIcon} alt="" className="login-main-btn-icon"/>
      Continue With Email
    </div>
    <GoogleLogin />
    <AppleLogin />
    </div>
  )
}



const LoginPage = () =>{
  const [pageState, setPageState] = useState(0);
  return (
    <div className='login-bg'>
    <div className="login-page">
      { pageState === 0 
      ?<LoginMain setPageState={setPageState}/>
      :<LoginEmail setPageState={setPageState}/>
      }
    </div>
    </div>
  )
}


export default LoginPage;