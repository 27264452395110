import React, { useEffect, useState, useCallback,useContext } from 'react';
import { useNavigate } from "react-router-dom";
import { LoginWithApple } from '../../api/winx-api';
import AuthContext from "../../context/AuthContext";

const AppleAuthPage = () => {
  const searchParams = new URLSearchParams(window.location.search);
  const navigate = useNavigate();
  const { setToken } = useContext(AuthContext);

  const handleLogin = async (token) =>{
    const response = await LoginWithApple(token);
    if(response.status === 401 || response.code !== 200) navigate('/login', {replace:true});
    const accessToken = response.data.access_token;
    setToken(accessToken);
    navigate('/', {replace:true});
  }

  useEffect(() => {
    const code = searchParams.get('code');
    const idToken = searchParams.get('id_token');
    if(!code || !idToken){
      navigate('/login', {replace:true});
      return;
    }
    handleLogin(idToken);
  },[]);
  return (
    <></>
  );
}

export default AppleAuthPage;