import { createContext, useState, useEffect } from 'react';
import axios from "axios";
import {UserProfile} from '../api/winx-api';


const AuthContext = createContext();
export default AuthContext;


export const AuthProvider = ({children}) => {
  const [authToken, setAuthToken] = useState(localStorage.getItem("token"));
  const [user, setUser] = useState({});

  const setToken = (token) =>{
    setAuthToken(token);
  }

  const updateProfile = async(authToken) =>{
    // const response = await UserProfile(authToken);
    // if(response.code !== 200 || response.status !== 200) {
    //   setUser({});
    //   return;
    // }
    // setUser(response.data.user);
  }

  useEffect(() => {
    if (authToken) {
      localStorage.setItem('token',authToken);
      // updateProfile(authToken);
    } else {
      localStorage.removeItem('token');
      // setUser({});
    }
  }, [authToken]);


  let contextData = {
    user:user,
    authToken:authToken,
    setToken:setToken,
  }

  return (
    <AuthContext.Provider value={contextData}>
      {children}
    </AuthContext.Provider>
  );
};