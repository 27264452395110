import "./Loading.css";

export const LoaderPage = () =>{
  return (
    <div className="loading-page">
      <div className="loader-default">
        Loading...
      </div>
    </div>
  )
}

export const Loader = () =>{
  //https://projects.lukehaas.me/css-loaders/
  return (
    <div className="loader-whlie-min">
      Loading...
    </div>
  )
}
