import React, { useEffect, useState, useCallback,useContext } from 'react';
import { useNavigate } from "react-router-dom";
import { LoginWithGoogle } from '../../api/winx-api';
import AuthContext from "../../context/AuthContext";

const GoogleAuthPage = () => {
  const searchParams = new URLSearchParams(window.location.search);
  const navigate = useNavigate();
  const { setToken } = useContext(AuthContext);

  const handleLogin = async (token) =>{
    const response = await LoginWithGoogle(token);
    if(response.status === 401 || response.code !== 200) navigate('/login', {replace:true});
    const accessToken = response.data.access_token;
    setToken(accessToken);
    navigate('/', {replace:true});
  }

  useEffect(() => {
    const credential = searchParams.get('credential');
    if(!credential){
      navigate('/login', {replace:true});
      return;
    }
    handleLogin(credential);
  },[]);
  return (
    <></>
  );
}

export default GoogleAuthPage;